/* styles for fivejs-marine-sports/pods/components/single-page/footer/styles.scss */
._contact-info_197e8o {
  td {
    vertical-align: top;

    &:first-child {
      width: 70px;
    }
  }
}

/* styles for fivejs-marine-sports/pods/components/single-page/our-services/styles.scss */
._fa-icon_2clvs4 {
  color:#5c8eb0;
  float: left;
}
